<template>
  <div id="carrello" :style="{ height: `${height}px` }">
    <div class="all" :style="{ height: `${height}px` }">
      <div>
        <div class="filtro">
          <el-input v-model="findCart" placeholder="CERCA MODELLO" suffix-icon="el-icon-search"></el-input>
        </div>
        <div>
          <el-table v-if="windowWidth < 992 && showFullTable == false" class="cart-table" :data="pagedCartRows" border
            stripe :height="heightTableBody" max-height="50vh" :row-style="{ height: '60px' }"
            empty-text="Nessun occhiale a carrello">
            <!-- <el-table-column type="index" ></el-table-column> -->
            <el-table-column prop="nome_modello" label="MODELLO" align="center"></el-table-column>
            <el-table-column label="CALIBRO-PONTE" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.calibro }}/{{ scope.row.naso }}</span>
              </template>
            </el-table-column>
            <el-table-column label="COLORE FRONTALE" align="center">

              <template slot-scope="scope">
                <el-tooltip v-if="scope.row && warnings[scope.row.colore_frontale]"
                  :content="warnings[scope.row.colore_frontale]" effect="light" placement="top">
                  <span style="position: absolute; right: 0; top: 0;"><svg width="27" height="27" fill="red"
                      viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="m22.454 20.992-9.87-18.334a.375.375 0 0 0-.661 0L2.051 20.993a.375.375 0 0 0 .33.552h19.745a.374.374 0 0 0 .328-.553Zm-9.264-1.7h-1.874v-1.876h1.874v1.875Zm-.187-2.997h-1.5l-.281-7.5h2.062l-.281 7.5Z">
                      </path>
                    </svg></span>
                </el-tooltip>
                <span>{{ scope.row.colore_frontale.replace('PL', '') }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="finishing" label="FINITURA FRONTALE" align="center">

              <template slot-scope="scope">
                <span v-if="scope.row.finishing == 'S'">LUCIDO</span>
                <span v-else-if="scope.row.finishing == 'M'">OPACO</span>
                <span v-else>NATURALE</span>
              </template>
            </el-table-column>
            <el-table-column prop="nome_asta" label="ASTE" align="center"></el-table-column>
            <el-table-column prop="FinishingTemple" label="FINITURA ASTE" align="center">

              <template slot-scope="scope">
                <span v-if="scope.row.FinishingTemple == 'S'">LUCIDO</span>
                <span v-else>OPACO</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="nome"
              label="PERSONALIZZAZIONE"
              align="center"
            ></el-table-column>
            <el-table-column label="COLORE PERSONALIZZAZIONE" align="center">
              <template
                slot-scope="scope"
              >{{ scope.row.colore_nome.toUpperCase() }}</template>
            </el-table-column>
            <el-table-column
              prop="note"
              label="NOTE"
              align="center"
              min-width="200"
            ></el-table-column>
            <el-table-column prop="quantita" label="QUANTITA'" align="center"></el-table-column>-->
            <el-table-column label="..." align="center">
              <!-- eslint-disable-next-line vue/no-unused-vars -->

              <template>
                <el-button @click="showFullTable = true" type="text" size="small">...</el-button>
              </template>
            </el-table-column>
            <el-table-column label="ELIMINA" align="center">

              <template slot-scope="scope">
                <el-button @click="sicuroCancellaRiga(scope.row.id)" type="text" size="small">ELIMINA</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-table v-else-if="windowWidth >= 992 || showFullTable == true" class="cart-table" :data="pagedCartRows"
            border stripe :height="heightTableBody" max-height="50vh" :row-style="{ height: '60px' }"
            empty-text="Nessun occhiale a carrello">
            <!-- <el-table-column type="index" ></el-table-column> -->
            <el-table-column prop="nome_modello" label="MODELLO" align="center"></el-table-column>
            <el-table-column label="CALIBRO-PONTE" align="center">

              <template slot-scope="scope">
                <span>{{ scope.row.calibro }}/{{ scope.row.naso }}</span>
              </template>
            </el-table-column>
            <el-table-column label="COLORE FRONTALE" align="center">

              <template slot-scope="scope">
                <el-tooltip v-if="scope.row && warnings[scope.row.colore_frontale]"
                  :content="warnings[scope.row.colore_frontale]" effect="light" placement="top">
                  <span style="position: absolute; right: 0; top: 0;"><svg width="27" height="27" fill="red"
                      viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="m22.454 20.992-9.87-18.334a.375.375 0 0 0-.661 0L2.051 20.993a.375.375 0 0 0 .33.552h19.745a.374.374 0 0 0 .328-.553Zm-9.264-1.7h-1.874v-1.876h1.874v1.875Zm-.187-2.997h-1.5l-.281-7.5h2.062l-.281 7.5Z">
                      </path>
                    </svg></span>
                </el-tooltip>
                <span>{{ scope.row.colore_frontale.replace('PL', '') }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="finishing" label="FINITURA FRONTALE" align="center">

              <template slot-scope="scope">
                <span v-if="scope.row.finishing == 'S'">LUCIDO</span>
                <span v-else-if="scope.row.finishing == 'M'">OPACO</span>
                <span v-else>NATURALE</span>
              </template>
            </el-table-column>
            <el-table-column prop="nome_asta" label="ASTE" align="center"></el-table-column>
            <el-table-column prop="FinishingTemple" label="FINITURA ASTE" align="center">

              <template slot-scope="scope">
                <span v-if="scope.row.FinishingTemple == 'S'">LUCIDO</span>
                <span v-else>OPACO</span>
              </template>
            </el-table-column>
            <el-table-column label="PERSONALIZZAZIONE" align="center">

              <template slot-scope="scope">
                <span v-if="scope.row.nome !== ''">{{ scope.row.nome }}</span>
                <span v-if="scope.row.nomeExtAstaDx !== ''">{{ scope.row.nomeExtAstaDx }}</span>
                <span v-if="scope.row.nomeIntAstaDx !== ''">{{ scope.row.nomeIntAstaDx }}</span>
              </template>
            </el-table-column>
            <el-table-column label="COLORE PERSONALIZZAZIONE" align="center">

              <template slot-scope="scope">
                <span v-if="scope.row.colore_nome !== ''">{{ scope.row.colore_nome.toUpperCase() }}</span>
                <span v-if="scope.row.coloreNomeExtAstaDx !== ''">{{ scope.row.coloreNomeExtAstaDx.toUpperCase()
                  }}</span>
                <span v-if="scope.row.coloreNomeIntAstaDx !== ''">{{ scope.row.coloreNomeIntAstaDx.toUpperCase()
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column label="POSIZIONE PERSONALIZZAZIONE" align="center">

              <template slot-scope="scope">
                <span v-if="scope.row.nome !== ''">FRONTALE</span>
                <span v-if="scope.row.nomeExtAstaDx !== ''">ESTERNO ASTA DESTRA</span>
                <span v-if="scope.row.nomeIntAstaDx !== ''">TOP ASTA DESTRA</span>
              </template>
            </el-table-column>
            <el-table-column prop="note" label="NOTE" align="center" min-width="200"></el-table-column>
            <el-table-column prop="quantita" label="QUANTITA'" align="center"></el-table-column>
            <el-table-column label="ELIMINA" align="center">

              <template slot-scope="scope">
                <el-button @click="sicuroCancellaRiga(scope.row.id)" type="text" size="small">ELIMINA</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="pagination">
          <el-pagination background @size-change="setSizePage" @current-change="setPage" :current-page.sync="page"
            :page-sizes="pageSizes" :page-size="this.pageSize" layout="sizes, jumper, total, prev, next"
            :total="this.cartRows.length"></el-pagination>
        </div>
      </div>
      <div class="note-and-buttons">
        <el-input type="textarea" :rows="6" placeholder="Note" v-model="noteCarrello"></el-input>
        <el-button-group class="action-group-button">
          <el-button :disabled="cartRows == 0" type="default" @click="elimCarrello = true">SVUOTA CARRELLO</el-button>
          <el-button :disabled="cartRows == 0" type="default" @click="confermaOrdine">CONFERMA ORDINE</el-button>
        </el-button-group>
      </div>
    </div>

    <!-- Ordine inviato -->
    <el-dialog class="confirm-add-dialog" width="550px" title="ORDINE INVIATO CON SUCCESSO" :visible.sync="confirmAdd"
      :modal="false" :show-close="false" :close-on-click-modal="false">
      <el-button-group>
        <el-button class="confirm-add-button" @click="goToConfigurator">VAI AL CONFIGURATORE</el-button>
        <el-button class="confirm-add-button" @click="goToStorico">VAI ALLO STORICO ORDINI</el-button>
      </el-button-group>
    </el-dialog>

    <!-- Cancella riga -->
    <el-dialog class="confirm-dialog" title="ELIMINARE PRODOTTO DAL CARRELLO?" :visible.sync="elimRiga" width="500px"
      :close-on-click-modal="false">
      <el-button-group>
        <el-button class="confirm-button" @click="elimRiga = false">ANNULLA</el-button>
        <el-button class="confirm-button" @click="cancellaRiga(idRigaDaCanc)">CONFERMA</el-button>
      </el-button-group>
    </el-dialog>

    <!-- Svuota carrello -->
    <el-dialog class="confirm-dialog" title="SVUOTARE IL CARRELLO?" :visible.sync="elimCarrello" width="500px"
      :close-on-click-modal="false">
      <el-button-group>
        <el-button class="confirm-button" @click="elimCarrello = false">ANNULLA</el-button>
        <el-button class="confirm-button" @click="svuotaCarrello">CONFERMA</el-button>
      </el-button-group>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import jslinq from "jslinq";
export default {
  data() {
    return {
      noteCarrello: "",
      findCart: "",
      page: 1,
      pageSize: 5,
      pageSizes: [5, 10, 20, 50, 100],
      height: 0,
      confirmAdd: false,
      elimRiga: false,
      elimCarrello: false,
      idRigaDaCanc: "",
      showFullTable: false,
      windowWidth: window.innerWidth,
    };
  },

  mounted: function () {
    // this.width = window.innerWidth;
    this.height = window.innerHeight - 80;
  },

  methods: {
    ...mapActions("cart", ["emptyCart", "deleteCartRow", "checkout"]),
    ...mapActions("user", ["getUserCart"]),
    setSizePage(val) {
      this.pageSize = val;
    },

    setPage(val) {
      this.page = val;
    },
    async svuotaCarrello() {
      this.elimCarrello = false;
      await this.emptyCart();
      await this.getUserCart();
    },
    sicuroCancellaRiga(id) {
      this.elimRiga = true;
      this.idRigaDaCanc = id;
    },

    cancellaRiga(id) {
      this.elimRiga = false;
      this.deleteCartRow(id);
      this.getUserCart();
    },
    confermaOrdine() {
      this.checkout(this.noteCarrello);
      this.getUserCart();
      this.confirmAdd = true;
    },
    goToConfigurator() {
      this.confirmAdd = false;
      this.$router.push("/");
    },
    goToStorico() {
      this.confirmAdd = false;
      this.$router.push("/storico");
      this.getUserCart();
    },
    findColorWarehouseInfo(color) {
      console.log("SEARCH COLOR", color, this.$store.state.frontali)
      return this.$store.state.frontali.colors.find((c) => {
        return c.U_THE_COLORE == color;
      });
    },

    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero based
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    }
  },

  computed: {
    ...mapState("cart", { cartRows: "cartRows" }),
    ...mapGetters("cart", { rowNumber: "rowNumber" }),
    pagedCartRows() {
      return this.filteredFront.slice(
        this.pageSize * this.page - this.pageSize,
        this.pageSize * this.page
      );
    },
    heightTableBody() {
      return this.pageSize * 60 + 71 + 1; // 60 è l'altezza delle righe della tabella impostata nel row-style, 71 è l'altezza dell'header
    },

    warnings() {
      const warnings = {};
      for (let row of this.cartRows) {
        const warehouseInfo = this.findColorWarehouseInfo(row.colore_frontale);
        if (warehouseInfo.GiacIta <= 1) {
          warnings[row.colore_frontale] = this.$t('message.Il_colore_{color}_è_esaurito,_data_di_arrivo:_{date}', {
            color: row.colore_frontale,
            date: this.formatDate(new Date(warehouseInfo.ShipDateIta))
          }).toString();
        }
      }
      return warnings;
    },

    queryCart() {
      return jslinq(this.cartRows);
    },

    filteredFront() {
      if (this.findCart && this.findCart != "") {
        return this.queryCart
          .where((c) => {
            return c.nome_modello.indexOf(this.findCart) >= 0;
          })
          .toList();
      } else {
        return this.cartRows;
      }
    },
  },
};
</script>

<style lang="less">
#carrello {
  width: 100%;

  .all {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .filtro {
    padding: 40px 15px 15px 15px;
  }

  @media (max-width: 992px) {
    .filtro {
      width: 50%;
    }
  }

  @media (min-width: 992px) {
    .filtro {
      width: 30%;
    }
  }

  .action-group-button {
    display: flex;
    justify-content: flex-end;
    height: 50px;
  }

  .confirm-dialog {
    padding-top: 10%;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.59);

    .el-dialog {
      color: @--color-primary;
      border: 1px solid @--color-primary;
      box-shadow: 0 0px 0px rgb(0 0 0);
      border-radius: 0px;

      .el-dialog__header {
        margin-top: 10%;

        span {
          color: @--color-primary;
          font-size: 16px;
        }
      }

      .el-dialog__body {
        padding: 50px 0px 0px 0px;
        margin-left: -1px;
        margin-right: -2px;

        .el-button-group {
          width: 100%;
        }
      }
    }
  }

  .confirm-button {
    width: 50%;
    font-size: 16px;
    margin-bottom: -1px;
  }

  .pagination {
    width: 100%;
    height: auto;
    min-height: 50px;

    .el-pagination {
      margin: 20px 0px;
      float: right;
      color: @--color-primary;
    }
  }

  .cart-table {
    background-color: @--table-header-background-color;

    .el-table__header,
    .el-table__body {
      div {
        word-break: normal !important;
      }
    }

    .el-table__empty-text {
      color: @--color-primary;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .confirm-add-dialog {
    padding: 10%;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.59);

    .el-dialog {
      color: @--color-primary;
      border: 1px solid @--color-primary;
      box-shadow: 0 0px 0px rgb(0 0 0);
      border-radius: 0px;

      .el-dialog__header {
        margin-top: 10%;

        span {
          color: @--color-primary;
          font-size: 16px;
        }
      }

      .el-dialog__body {
        padding: 50px 0px 0px 0px;
        margin-left: -1px;
        margin-right: -2px;

        .el-button-group {
          width: 100%;
        }
      }
    }
  }

  .confirm-add-button {
    width: 50%;
    font-size: 16px;
    margin-bottom: -1px;
  }
}
</style>